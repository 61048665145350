.magic-dots.slick-dots ul {
  padding: 0;
  display: flex;
  transition: all 0.2s;
  position: relative;
  margin: 0px;
}

.magic-dots.slick-dots .dot,
.magic-dots.slick-dots .small {
  margin-left: 2px;
  margin-right: 2px;
  height: 6px;
  width: 6px;
  display: inline-block;
  flex-shrink: 0;
  border-radius: 9999px;
  background: white;
}

.magic-dots.slick-dots .small {
  height: 4.5px;
  width: 4.5px;
  opacity: 0.6;
  margin-right: 3px;
}
